<template>
  <pp-form class="pp-card shadow-none" @submit.prevent="handleSubmit">
    <pp-card-title class="font-semibold text-2xl">Interest & Rewards Claim</pp-card-title>
    <pp-card-subtitle>
      <pp-simple-data>
        <template #left>
          <pp-checkbox @input="handleSelectAll" :value="selectedAll">Select All</pp-checkbox>
        </template>

        <template #right>
          <div>
            <div class="text-sm text-right">
              <div>Show balance above</div>
            </div>

            <div class="flex items-center">
              <pp-formatted-number
                class="font-semibold text-base"
                currency="USD"
                :min-decimal="0"
                :max-decimal="2"
                :value="balanceThreshold == 0 ? 0.01 : balanceThreshold"
              ></pp-formatted-number>

              <SliderInput
                class="w-24 sm:w-36 ml-2"
                min="0"
                max="500"
                step="10"
                @input="$emit('update:balance-threshold', $event)"
                :value="balanceThreshold"
              />
            </div>
          </div>
        </template>
      </pp-simple-data>
    </pp-card-subtitle>

    <pp-card-body class="p-0 overflow-auto h-[50vh]">
      <pp-data-table
        class="table-auto min-w-[760px]"
        :data="claimableYields"
        :sorting-rules="activeSortingRules"
      >
        <template v-slot:headers>
          <pp-tr>
            <pp-th>Token</pp-th>
            <pp-th></pp-th>
            <pp-th>Claim from</pp-th>
            <pp-th>
              Claimable
              <SortingToggleBtn v-model="claimableSort" />
            </pp-th>
            <pp-th>
              Amount
              <SortingToggleBtn v-model="amountSort" />
            </pp-th>
          </pp-tr>
        </template>

        <template v-slot:item="{ item }">
          <pp-tr :key="item.key" :class="{ 'bg-p-green-100': item.selected }">
            <pp-td>
              <pp-checkbox v-model="item.selected"></pp-checkbox>
            </pp-td>

            <pp-td nowrap>
              <img
                class="w-6 inline-block"
                :src="item.yield.yield.tokenAmount.token.img"
                :alt="item.yield.yield.tokenAmount.token.name"
              />
              <div
                class="ml-2 font-semibold inline-block"
                v-text="item.yield.yield.tokenAmount.token.symbol"
              ></div>
            </pp-td>

            <pp-td nowrap>
              <div class="text-sm">
                <span
                  class="inline-block p-1 text-xs bg-p-blue-100 rounded-md"
                  v-text="item.yield.origin.token.name"
                ></span>

                <span
                  class="ml-1"
                  v-text="item.yield.origin.source.className === 'Wallet' ? 'wallet' : 'staked'"
                ></span>
              </div>
            </pp-td>

            <pp-td>
              <pp-formatted-number
                :humanize="false"
                :value="item.yield.yield.tokenAmount.formattedAmount()"
              ></pp-formatted-number>
            </pp-td>

            <pp-td>
              ~
              <pp-formatted-price
                :currency="item.yield.yield.valuation.currency"
                :value="item.yield.yield.valuation.amount"
              ></pp-formatted-price>
            </pp-td>
          </pp-tr>
        </template>
      </pp-data-table>
    </pp-card-body>

    <pp-card-body class="border-b border-t border-p-gray-100">
      <pp-simple-data class="text-2xl font-semibold">
        <template #left>Total Claiming</template>
        <template #right>
          ~
          <pp-formatted-price :value="totalClaims"></pp-formatted-price>
        </template>
      </pp-simple-data>

      <div class="py-4">
        <div v-if="gasInfo.is('loading') || gasInfo.is('resolved')">
          <div class="flex items-center">
            Current gas price:
            <div class="ml-2 inline-block font-semibold">
              <pp-skeleton-loader v-if="gasInfo.is('loading')" class="w-32"></pp-skeleton-loader>

              <template v-else-if="gasInfo.is('resolved')">
                <pp-formatted-number
                  :humanize="false"
                  :min-decimal="0"
                  :value="gasInfo.response.gasPrice.rawAmount() / 1000000000"
                ></pp-formatted-number>
                gwei
              </template>
            </div>
          </div>
          <div class="flex items-center">
            Est. gas limit:

            <div class="ml-2 inline-block font-semibold">
              <pp-skeleton-loader v-if="gasInfo.is('loading')" class="w-32"></pp-skeleton-loader>
              <pp-formatted-number
                v-else-if="gasInfo.is('resolved')"
                :humanize="false"
                :min-decimal="0"
                :value="gasInfo.response.gasLimit"
              ></pp-formatted-number>
            </div>
          </div>
          <div class="flex items-center">
            Est. gas cost:
            <div class="ml-2 inline-block">
              <pp-skeleton-loader v-if="gasInfo.is('loading')" class="w-32"></pp-skeleton-loader>

              <template v-else-if="gasInfo.is('resolved')">
                <pp-token-amount
                  class="font-semibold"
                  :humanize="false"
                  :min-decimal="0"
                  :max-decimal="5"
                  :token-amount="gasInfo.response.gasCost"
                ></pp-token-amount>

                <div class="ml-2 inline-flex items-center">
                  (
                  <pp-token-amount-valuation
                    :token-amount="gasInfo.response.gasCost"
                  ></pp-token-amount-valuation>
                  )
                </div>
              </template>
            </div>
          </div>
        </div>

        <div v-else-if="gasInfo.is('rejected')">
          <pp-alert color="p-red">
            Unable to estimate gas

            <template v-slot:actions>
              <pp-btn variant="accent" color="p-red" @click="handleSelectChanges">Retry</pp-btn>
            </template>
          </pp-alert>
        </div>
      </div>
    </pp-card-body>

    <pp-card-actions class="mt-4 flex space-x-2">
      <div class="flex-grow"></div>
      <pp-btn @click="$attrs.deactivate" variant="text" type="button">Close</pp-btn>
      <pp-btn
        :disabled="selectedClaims.length === 0 || !gasInfo.is('resolved')"
        :loading="form.is('loading')"
        type="submit"
      >
        Claim
      </pp-btn>
    </pp-card-actions>
  </pp-form>
</template>

<script>
  import Vue from 'vue'
  import SliderInput from './SliderInput.vue'
  import SortingToggleBtn from '@/components/SortingToggleBtn.vue'
  import BigNumberjs from 'bignumber.js'
  import { Sdk } from '@pendle/sdk'
  import PromiseHandler, { createState } from '@/domains/PromiseHandler'
  import { generateEtherscanTxUrl } from '@/assets/helpers'
  import { sdkChainId } from '@/app-config/network'

  export default Vue.extend({
    components: { SliderInput, SortingToggleBtn },
    props: {
      yields: { type: Array, default: () => [] },
      balanceThreshold: { type: [String, Number], default: 0 },
    },
    data() {
      return {
        claimableYields: this.$props.yields,
        gasInfoTimeout: null,
        form: createState(),
        gasInfo: createState(),
        claimableSort: {
          type: 'none',
          activatedAt: null,
          ascending: (a, b) =>
            new BigNumberjs(a.yield.yield.tokenAmount.formattedAmount())
              .minus(new BigNumberjs(b.yield.yield.tokenAmount.formattedAmount()))
              .toFixed(),
          descending: (a, b) =>
            new BigNumberjs(b.yield.yield.tokenAmount.formattedAmount())
              .minus(new BigNumberjs(a.yield.yield.tokenAmount.formattedAmount()))
              .toFixed(),
        },
        amountSort: {
          type: 'none',
          activatedAt: null,
          ascending: (a, b) =>
            new BigNumberjs(a.yield.yield.valuation.amount)
              .minus(new BigNumberjs(b.yield.yield.valuation.amount))
              .toFixed(),
          descending: (a, b) =>
            new BigNumberjs(b.yield.yield.valuation.amount)
              .minus(new BigNumberjs(a.yield.yield.valuation.amount))
              .toFixed(),
        },
      }
    },
    methods: {
      handleSelectAll() {
        if (this.selectedAll) {
          this.claimableYields.forEach((item) => (item.selected = false))
        } else {
          this.claimableYields.forEach((item) => (item.selected = true))
        }
      },
      handleSubmit() {
        const { signer, provider } = this.$store.getters['wallet/identity']
        new PromiseHandler(
          () =>
            new Promise((resolve, reject) => {
              new Sdk({
                signer,
                provider,
                chainId: sdkChainId,
              })
                .claimYields(this.submissionParams)
                .then((response) => {
                  response.wait(1).then(() => setTimeout(() => resolve(response), 2000))
                })
                .catch((error) => reject(error))
            }),

          this.form
        )
          .execute()
          .then((response) => {
            this.$notification.success({
              title: 'Claim',
              text: 'Claimed Incentives & Interests',
              action: {
                url: generateEtherscanTxUrl(response.hash),
                urlText: 'View on explorer',
              },
            })

            this.$emit('success', response)
          })
          .catch(() => {
            this.$notification.error({
              title: 'Claim',
              text: 'Unable to claim Incentives & Interests',
            })
          })
      },
      handleSelectChanges() {
        this.gasInfo.state = 'idle'
        clearTimeout(this.gasInfoTimeout)

        if (this.selectedClaims.length) {
          this.gasInfo.state = 'loading'
          const { signer, provider } = this.$store.getters['wallet/identity']
          const { wallet } = this.$store.state.wallet

          this.gasInfoTimeout = setTimeout(() => {
            new PromiseHandler(
              () =>
                new Sdk({
                  signer,
                  wallet,
                  provider,
                  chainId: sdkChainId,
                }).estimateGasForClaimYields(this.submissionParams),
              this.gasInfo
            ).execute({ force: true })
          }, 800)
        }
      },
    },
    computed: {
      submissionParams() {
        const [walletYields, stakingPoolYields] = ['Wallet', 'StakingPool'].map((yieldSource) =>
          this.selectedClaims.filter((item) => item.yield.origin.source.className === yieldSource)
        )

        const [yts, ots, lps] = ['yt', 'ot', 'lp'].map((tokenType) =>
          walletYields
            .filter((item) => item.yield.origin.token.tokenCategories.includes(tokenType))
            .filter((item) => item.yield.acquisitionMethod === 'accrue')
            .map((item) => item.yield.origin.token)
        )

        const [rewardStakingPools, interestStakingPools] = ['reward', 'interest'].map((yieldType) =>
          stakingPoolYields
            .filter((item) => item.yield.yieldType === yieldType)
            .map((item) => item.yield.origin.source)
            .map((item) => item.sdkSelf)
        )

        const tokensToDistribute = walletYields
          .filter((item) => item.yield.acquisitionMethod === 'distribution')
          .map((item) => item.yield.yield.tokenAmount.token)

        return {
          yts,
          ots,
          lps,
          rewardStakingPools,
          interestStakingPools,
          tokensToDistribute,
        }
      },
      selectedAll() {
        return (
          this.claimableYields.length > 0 && this.claimableYields.every((_yield) => _yield.selected)
        )
      },
      selectedClaims() {
        return this.claimableYields.filter((_yield) => _yield.selected)
      },
      activeSortingRules() {
        return [this.claimableSort, this.amountSort]
          .filter((sortType) => sortType.type !== 'none')
          .sort((a, b) => a.activatedAt - b.activatedAt)
          .map((sortType) => sortType[sortType.type])
      },
      totalClaims() {
        return this.selectedClaims
          .reduce(
            (acc, claim) => acc.plus(new BigNumberjs(claim.yield.yield.valuation.amount)),
            new BigNumberjs('0')
          )
          .toFixed()
      },
    },
    watch: {
      yields: {
        immediate: true,
        handler(newVal) {
          this.claimableYields = newVal
            .map((_yield, index) => ({
              yield: _yield,
              key: `${index}--${_yield.origin.source.className}--${_yield.origin.token.address}--${_yield.yield.tokenAmount.token.address}`,
              selected: false,
            }))
            .sort((a, b) =>
              a.yield.yield.tokenAmount.token.symbol > b.yield.yield.tokenAmount.token.symbol
                ? 1
                : a.yield.yield.tokenAmount.token.symbol < b.yield.yield.tokenAmount.token.symbol
                ? -1
                : 0
            )
        },
      },
      selectedClaims() {
        this.handleSelectChanges()
      },
    },
  })
</script>
